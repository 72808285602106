<template>
  <div class="bt-home pb-5 mb-5 pt-5">
    <ChartCabang />
    <v-col col="12" class="pa-5">
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Silahkan Ketik Untuk Mencari..." single-line hide-details></v-text-field>
        </v-card-title>
        <div class="table-container pa-4">
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            class="small-text"
            mobile-breakpoint="220"
            :headers="headers"
            :items="desserts"
            :search="search"
            item-key="name"
            :footer-props="{
              'items-per-page-options': [5, 10, 25],
              'show-current-page': true,
            }"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in (visibleItems = items)" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ item.menagih }}</td>
                  <td class="text-center">{{ item.tertagih }}</td>
                  <td class="text-right">{{ formatRupiah(item.nominal) }}</td>
                </tr>
                <tr v-if="items.length">
                  <td class="text-left font-weight-bold" style="font-size: 11px">TOTAL TERTAGIH</td>
                  <td class="text-center font-weight-bold" style="font-size: 11px">
                    {{ getTotalMenagih(visibleItems) }}
                  </td>
                  <td class="text-center font-weight-bold" style="font-size: 11px">
                    {{ getTotalTertagih(visibleItems) }}
                  </td>
                  <td class="text-right font-weight-bold" style="font-size: 11px">
                    {{ formatRupiah(getTotalNominal(visibleItems)) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import ChartCabang from "@/components/Chart/ChartCabang.vue";
export default {
  name: "LaporanPenagihan",
  components: {
    ChartCabang,
  },
  data() {
    return {
      loading: false,
      search: "",
      visibleItems: [], // Data yang sedang ditampilkan
      headers: [
        {
          text: "Nama Petugas",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Menagih",
          value: "menagih",
          align: "center",
        },
        {
          text: "Tertagih",
          value: "tertagih",
          align: "center",
        },
        {
          text: "Nominal",
          value: "nominal",
          align: "end",
        },
      ],
      desserts: [
        {
          name: "Suhernah",
          menagih: 18,
          tertagih: 1,
          nominal: 12000,
        },
        {
          name: "Damayanti",
          menagih: 13,
          tertagih: 5,
          nominal: 65000,
        },
        {
          name: "Silva Salsabila Basriyah",
          menagih: 13,
          tertagih: 2,
          nominal: 60000,
        },
        {
          name: "Anah Nurhasanah",
          menagih: 13,
          tertagih: 4,
          nominal: 95000,
        },
        {
          name: "Abdul Rohman",
          menagih: 12,
          tertagih: 6,
          nominal: 80000,
        },
        {
          name: "Roheni",
          menagih: 12,
          tertagih: 5,
          nominal: 154000,
        },
        {
          name: "Nur Riska Septiani",
          menagih: 10,
          tertagih: 4,
          nominal: 236000,
        },
        {
          name: "Dhea Saputri",
          menagih: 9,
          tertagih: 3,
          nominal: 40000,
        },
        {
          name: "Ressa Kartikasari",
          menagih: 8,
          tertagih: 3,
          nominal: 40000,
        },
        {
          name: "Muhammad Arif Syarifudin",
          menagih: 7,
          tertagih: 3,
          nominal: 55000,
        },
        {
          name: "Afti Andiyani",
          menagih: 7,
          tertagih: 3,
          nominal: 171000,
        },
      ],
    };
  },
  methods: {
    formatRupiah(value) {
      return new Intl.NumberFormat("id-ID").format(value);
    },
    getTotalMenagih(items) {
      return items?.reduce((total, item) => total + item.menagih, 0) || 0;
    },
    getTotalTertagih(items) {
      return items?.reduce((total, item) => total + item.tertagih, 0) || 0;
    },
    getTotalNominal(items) {
      return items?.reduce((total, item) => total + item.nominal, 0) || 0;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
};
</script>
<style scoped>
.overflow-x-auto {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
::v-deep(.v-data-table) {
  font-size: 11px !important;
}

::v-deep(.v-data-table th),
::v-deep(.v-data-table td) {
  font-size: 11px !important;
}
::v-deep(.v-data-footer) {
  font-size: 8px !important;
}
::v-deep(.v-data-footer__select .v-select__selections .v-select__selection--comma) {
  font-size: 8px !important;
}
::v-deep(.v-input) {
  font-size: 12px !important;
}
::v-deep(.v-label) {
  font-size: 12px !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
